
import { Component, Vue } from "vue-property-decorator";
import { mapActions } from "vuex";
import { UFliterals } from "@/utils/literals";
import { validationMixin } from "vuelidate";
import { required, alphaNum } from "vuelidate/lib/validators";
import { UFFormValidationMessage } from "@/utils/literals/forms";
import {apiUrl} from "@/env";
import store from "@/store";

@Component({
  name: "LoginForm",
  mixins: [validationMixin],
  methods: {
    ...mapActions(["getToken"]),
  },
  validations: {
    username: { required, alphaNum },
    password: { required },
  },
})
export default class LoginForm extends Vue {
  username = "";
  password = "";
  showPassword = false;
  loading = false;
  getToken!: (payload: {
    credentials: {
      username: string;
      password: string;
    };
    next: CallableFunction;
    errorNext?: CallableFunction;
  }) => any;

  login(): void {
    this.loading = true;
    let redirect = this.$route.query.redirect;
    this.getToken({
      credentials: {
        username: this.username,
        password: this.password,
      },
      next: () => {
        this.$router.push(
          typeof redirect === "string" ? redirect : { name: "home" }
        );
      },
      errorNext: () => {
        this.loading = false;
      },
    });
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  getUFErrorMessage(key: string): string {
    return UFFormValidationMessage[key] || "";
  }

  get usernameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.username.$dirty) return errors;
    !this.$v.username.required &&
      errors.push(this.getUFErrorMessage("required"));
    !this.$v.username.alphaNum &&
      errors.push(this.getUFErrorMessage("alphaNumericFormat"));
    return errors;
  }

  get passwordErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.password.$dirty) return errors;
    !this.$v.password.required &&
      errors.push(this.getUFErrorMessage("required"));
    return errors;
  }

  get isSubmitDisable(): boolean {
    return this.$v.$invalid || this.loading;
  }



}
